exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-docs-user-manual-index-js": () => import("./../../../src/pages/docs/user-manual/index.js" /* webpackChunkName: "component---src-pages-docs-user-manual-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-screenshots-index-js": () => import("./../../../src/pages/screenshots/index.js" /* webpackChunkName: "component---src-pages-screenshots-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-feature-page-js": () => import("./../../../src/templates/feature-page.js" /* webpackChunkName: "component---src-templates-feature-page-js" */),
  "component---src-templates-forbidden-page-js": () => import("./../../../src/templates/forbidden-page.js" /* webpackChunkName: "component---src-templates-forbidden-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-people-js": () => import("./../../../src/templates/people.js" /* webpackChunkName: "component---src-templates-people-js" */),
  "component---src-templates-screenshots-js": () => import("./../../../src/templates/screenshots.js" /* webpackChunkName: "component---src-templates-screenshots-js" */),
  "component---src-templates-simple-page-js": () => import("./../../../src/templates/simple-page.js" /* webpackChunkName: "component---src-templates-simple-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-user-manual-js": () => import("./../../../src/templates/user-manual.js" /* webpackChunkName: "component---src-templates-user-manual-js" */)
}

